import { useEffect, useState } from "react"
import RepetitionTrainer from "../app/repetitionTrainer"

export const useTrainer = (moveTree, makeMove, getTurnColor, userColor, setUserColor, updateFen, reset, undo, setUndo, currentView, currentRepertoireData, refresh) => {
    const [incorrectMove, setIncorrectMove] = useState(false)
    const [drawable, setDrawable] = useState([])
    
    const start = () => {
        if (trainer.move_tree) {
            if (trainer.move_tree.color !== getTurnColor()) {
                setTimeout(() => trainer.trainerMove(makeMove), 200)
            }
        }
    }

    const [trainer, setTrainer] = useState(new RepetitionTrainer(reset, start, setIncorrectMove))
    
    // useEffect(() => {
    //     // If the computer is white, it needs to make the first move.
    //     if (trainer.move_tree) {
    //         console.log(userColor)
    //         console.log(getTurnColor())
    //         if (userColor !== getTurnColor()) {
    //             setTimeout(() => trainer.trainerMove(makeMove), 200)
    //         }
    //     }
    // }, [refresh, userColor, currentView, currentRepertoireData])

    const skip = (move_num) => {
        for (let index = 0; index < move_num; index++) {
            onMoveQuick()
        }
    }

    const onMoveQuick = () => {
        let subs = trainer.move_tree.getCursor().subsequent
        let keys = Object.keys(subs)
        trainer.validateMove(subs[keys[0]].from, subs[keys[0]].to, incorrectMove)
        makeMove(subs[keys[0]].from, subs[keys[0]].to)
        setDrawable([])
        trainer.trainerMove(makeMove)
    }

    const onMove = (from, to) => {
        if(trainer.validateMove(from, to, incorrectMove)) {
            makeMove(from, to)
            setDrawable([])
            setTimeout(() => trainer.trainerMove(makeMove), 200)
        } else {
            setTimeout(() => {
                setUndo(!undo);
                if(trainer.numIncorrectMoves >= 2 && trainer.numIncorrectMoves < 3) {
                    setDrawable(trainer.getDrawObjectsValidPieces())
                } else if (trainer.numIncorrectMoves >= 3) {
                    setDrawable(trainer.getDrawObjectsValidMoves())
                } 
            }, 500)
        }
    }

    const setMoveTree = (tree) => {
        trainer.setMoveTree(tree)
        trainer.reset()
        setUserColor(trainer.user_color)
        reset()
        // start()
    }

    return [onMove, setMoveTree, incorrectMove, setIncorrectMove, start, drawable, skip]
}