import styled from 'styled-components'
import constants from '../config/constants'

export const RightControlBoxContainer = styled.div`
    margin-left: 15px;

    max-width: 260px;
    width: 100%;
    height: 570px;

    border: 1px solid #000000;
    border-top: 3px solid #000000;

    // padding: 30px 30px;

    display: flex;
    flex-direction: column;
    display: none;

    @media ${constants.breakpoints.STABLET} {
        display: none;
    }
`

export const TopControlBoxContainer = styled.div`
    max-width: 260px;
    width: 100%;
    height: 570px;

    border: 1px solid #000000;
    border-top: 3px solid #000000;

    padding: 30px 30px;
    display: none;

    @media ${constants.breakpoints.STABLET} {
        display: block;
        display: none;
        margin: 0;
        height: 100%;
        max-width: 570px;
        width: 100%;
    }
`
export const BottomControlBoxContainer = styled.div`
    max-width: 260px;
    width: 100%;
    height: 570px;

    border: 1px solid #000000;
    border-top: 3px solid #000000;

    padding: 30px 30px;

    display: block;
    margin: 0;
    height: 330px;
    max-width: 570px;
    width: 100%;

    @media ${constants.breakpoints.STABLET} {
        display: block;
        margin: 0;
        height: 330px;
        max-width: 570px;
        width: 100%;
    }
`

export const Header = styled.div`

    display: flex;
    
    padding: 10px 15px;

    align-items: center;
`

export const HeaderTitle = styled.h1`
    margin: 0;
    // padding-left: 15px;
`

export const StatusBoxContainer = styled.div`
    width: 100%;
    // height: 80px;

    border: 1px solid #000000;
    border-top: 3px solid #000000;

    margin-top: auto;
    
    // padding: 20px 20px;

    display: flex;
    flex-direction: column;

    max-width: 650px;
    width: 100%;

    margin-top: 20px;
`

export const MoveBox = styled.div`
    width: 27px;
    height: 27px;

    // padding-top: 1px;
    padding-left: 1px;

    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: ${props => {
        switch(props.color) {
            case "white":
                return "#000"
            case "black":
                return "#fff"
            default:
                return "#000"
        }
    }};

    background: ${props => {
        switch(props.color) {
            case "white":
                return "#fff"
            case "black":
                return "#000"
            default:
                return "#fff"
        }
    }};
    border: 1.5px solid #000;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    &:before {
        content: ${props => {
            switch(props.color) {
                case "white":
                    return "'W'"
                case "black":
                    return "'B'"
                default:
                    return "'W'"
            }
        }};
    }
`

export const StatusBoxWrapper = styled.div`
    padding: 10px 15px;
    display: flex;
`

export const StatusBoxText = styled.h3`
    margin: 0;
    padding-left: 15px;
`

export const BlackLine = styled.div`
    margin-top: auto;
    // height: 10px;
    width: 100%;
    padding: 3px 15px 2px 15px;
    
    color: #fff;
    font-size: 12px;

    background-color: #000;
`