import React from 'react'
import { DescriptionDarkText, DescriptionText, SuperFooterContainer, SuperFooterWrapper } from '../../elements/Landing'

export const Description = () => {
    return (
        <SuperFooterContainer>
            <SuperFooterWrapper>
                <DescriptionText>
                    FourKnights is a <DescriptionDarkText>spaced repetition tool</DescriptionDarkText> to help you <DescriptionDarkText>learn and build</DescriptionDarkText> new chess repertoires.
                </DescriptionText>
            </SuperFooterWrapper>
        </SuperFooterContainer>
    )
}
