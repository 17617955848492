import React from 'react'
import { Input } from 'antd'
import { FormattedTextBox } from '../../elements/PgnParser'

const { TextArea } = Input

export const PgnTextBox = ({ pgn, setPgn }) => {

    return (
        <FormattedTextBox
            value={pgn}
            onChange={(e) => setPgn(e.target.value)}
            placeholder="Paste your PGN here."
            autoSize={{ minRows: 4, maxRows: 4 }}
            width="100%"
        />
    )
}
