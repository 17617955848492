import React from 'react'
import { Board } from '../components/Board/Board'
import { DemoBoard } from '../components/DemoBoard.js/DemoBoard'
import { Footer } from '../components/Footer/Footer'
import { SuperFooter } from '../components/Footer/SuperFooter'
import { Logo } from '../components/Logo/Logo'
import { BoardGrid, BoardGridWrapper, Grid } from '../elements/Grid'
import { ButtonRow, Container, DarkButton, LightButton, Subtitle, Wrapper, PuzzleText, InnerWrapper, PuzzleColumnWrapper, PuzzleIconRow, PuzzleIconCol, PuzzleOriginText, PuzzleTitleText, PuzzleTextWrapper, PuzzleOpeningName } from '../elements/Landing'
import { Controller, Scene } from 'react-scrollmagic';
import { Avatar, Tooltip } from 'antd'
import KasparovIcon from '../images/kasparov.jpeg'
import KorchnoiIcon from '../images/korchnoi.jpeg'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Description } from '../components/Description/Description'
import { HashLink as Link } from 'react-router-hash-link'


export const Landing = () => {
    return (
        <Container>
            <Wrapper>
                <Logo/>
                {/* <Subtitle>
                    Learn chess openings with spaced repetition
                </Subtitle> */}
                <ButtonRow>
                    <Link to='/study'><DarkButton>Get Started</DarkButton></Link>
                    <Link smooth to='/#demo'><LightButton>Demo</LightButton></Link>
                </ButtonRow>
            </Wrapper>
            {/* <Controller>
            <Scene duration={1} pin={true} enabled={true}>
                <p>HELLO!</p>
            </Scene>
            </Controller> */}

            {/* <PuzzleColumnWrapper>
                <InnerWrapper>
                    <Controller>
                        <Scene duration={300} offset={200} pin={true} enabled={true} indicators={true}> 
                        <PuzzleText>
                            What’s the next move in the Queen’s Gambit?
                        </PuzzleText>
                        </Scene>
                    </Controller>
                </InnerWrapper>

                <Controller>
                    <Scene duration={1000} offset={200} pin={true} enabled={true} indicators={true}> 
                        {(progress) => (
                            <InnerWrapper>
                                <Grid>
                                    <BoardGridWrapper>
                                        <BoardGrid>
                                            <DemoBoard/>
                                        </BoardGrid>
                                    </BoardGridWrapper>
                                </Grid>
                            </InnerWrapper>
                        )}
                    </Scene>
                </Controller>
            </PuzzleColumnWrapper> */}

            <Description/>

            <PuzzleColumnWrapper>
                <InnerWrapper>
                    <PuzzleTextWrapper  id="demo">
                        <PuzzleTitleText style={{marginBottom: '30px'}}>
                            <strong>Try out the training board.</strong>
                        </PuzzleTitleText>
                        <PuzzleIconRow>
                            <PuzzleIconCol>
                                <Avatar size={80} style={{marginBottom: '10px'}} src={KasparovIcon}/>
                                <p>G. Kasparov</p>
                            </PuzzleIconCol>
                            <PuzzleIconCol>
                                vs.
                            </PuzzleIconCol>
                            <PuzzleIconCol>
                                <Avatar size={80} style={{marginBottom: '10px'}} src={KorchnoiIcon} />
                                <p>V. Korchnoi</p>
                            </PuzzleIconCol>
                        </PuzzleIconRow>
                        <PuzzleText>
                            This is 5 moves into a drawn game from the 1990 <i>Paris Immopar rapid</i>. 
                        </PuzzleText>
                        <PuzzleText>
                            Kasparov has the white pieces and is next to move.
                        </PuzzleText>
                        <PuzzleTitleText>
                            <strong>What did Kasparov play next?</strong>  <Tooltip color={'#000'} placement="left" title="Try to guess the best move in this position. The computer will play Korchnoi's moves until the end of the opening phase."><PuzzleText style={{marginLeft: '10px'}}><InfoCircleOutlined style={{fontSize: '1rem'}}/></PuzzleText></Tooltip>
                        </PuzzleTitleText>
                        <PuzzleOpeningName>
                        English Opening: King's English. Four Knights Variation
                        </PuzzleOpeningName>
                    </PuzzleTextWrapper>
                </InnerWrapper>
                <InnerWrapper>
                    <Grid>
                        <BoardGridWrapper>
                            <BoardGrid>
                                <DemoBoard/>
                            </BoardGrid>
                        </BoardGridWrapper>
                    </Grid>
                </InnerWrapper>
            </PuzzleColumnWrapper>

            <SuperFooter/>

            <Footer/>
        </Container>
    )
}
