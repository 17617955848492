import Chess from 'chess.js'

class RepetitionTrainer {
    constructor(resetBoard, start, setIncorrectMove, move_tree=null) {
        this.setIncorrectMove = setIncorrectMove
        this.numIncorrectMoves = 0
        this.resetBoard = resetBoard
        this.start = start
        this.chess = new Chess()
        this.move_tree = move_tree
        this.user_color = move_tree ? move_tree.color : null
        this.current_time = new Date()
    }

    setMoveTree(tree) {
        this.move_tree = tree
        this.user_color = tree ? tree.color : null
    }

    validateMove(from, to, incorrect_move, numIncorrectMoves) {
        if(!this.move_tree) return false

        if (this.testEndCondition()) return 
        if (this.move_tree.isStart()) {
            this.current_time = new Date()
        }

        const move = this.chess.move({ from: from, to: to, promotion: 'q'})

        if(this.move_tree.isValidMove(move)) {
            if (!incorrect_move) {
                this.move_tree.recordMove(move, this.current_time, true)
            }
            this.setIncorrectMove(false)
            this.numIncorrectMoves = 0
            this.move_tree.advanceCursor(move)
            return true
        } else {
            if (!incorrect_move) {
                this.move_tree.recordMove(move, this.current_time, false)
            }
            setTimeout(() => this.setIncorrectMove(true), 500)
            this.numIncorrectMoves += 1
            this.chess.undo()
            return false
        }
    }

    trainerMove(makeMove) {
        if (this.testEndCondition()) return 
        if (this.move_tree.isStart()) {
            // console.log("IS START")
            this.current_time = new Date()
        }

        const move = this.move_tree.getNextMove(this.current_time)
        makeMove(move['from'], move['to'])
        this.chess.move({from: move['from'], to: move['to']})
        this.move_tree.advanceCursor(move)

        if (this.testEndCondition()) return 
    }    
    
    testEndCondition() {
        if(this.move_tree.isEnd()) {
            setTimeout(() => this.reset(), 500)
            return true
        }
        return false
    }

    getDrawObjectsValidPieces() {
        const valid_pieces = []
        const valid_moves = this.move_tree.getValidMoves()
        for (const move in Object.keys(valid_moves)) {
            valid_pieces.push({
                'orig': valid_moves[move].from,
                'brush': 'green'
            })
        }
        return valid_pieces
    }

    getDrawObjectsValidMoves() {
        const valid_pieces = []
        const valid_moves = this.move_tree.getValidMoves()
        for (const move in Object.keys(valid_moves)) {
            valid_pieces.push({
                'orig': valid_moves[move].from,
                'dest': valid_moves[move].to,
                'brush': 'green'
            })
        }
        return valid_pieces
    }

    reset() {
        // console.log('reset')
        if(this.move_tree) {
            this.move_tree.resetCursor()
        }
        this.chess.reset()
        this.resetBoard()
        this.start()
    }
}

export default RepetitionTrainer