import styled from 'styled-components'
import constants from '../config/constants'
import { Button } from 'antd'


export const Container = styled.div`
    margin-top: 20vh;
    width: 100%;
    height: min-content;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;

`

export const Wrapper = styled.div`
    max-width: 1300px;
    width: 100%;

    padding: 0 1rem;

    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;

    margin-bottom: 20vh;
`

export const PuzzleColumnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1100px;
    padding: 6rem 2rem;

    @media ${constants.breakpoints.TABLET} {
        flex-direction: column;
    }
`

export const InnerWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const LogoWrapper = styled.div`
    max-width: 700px;
    width: 100%;
    margin-bottom: 30px;
`

export const Subtitle = styled.h1`
    margin: 0;
    color: #818181;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 40px;
`

export const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;

    @media ${constants.breakpoints.STABLET} {
        flex-direction: column;
    }
`

export const DarkButton = styled(Button)`
    background-color: #000;
    color: #fff;
    border-radius: 3px;
    border: 1px solid #000000;
    font-size: 13px;
    
    width: 185px;
    height: 40px;

    &:hover {
        border: 1px solid #000000;
        color: #000;
    }

    &:focus {
        border: 1px solid #000000;
        color: #000;
    }

`

export const LightButton = styled(Button)`
    background-color: #fff;
    color: #000;
    border-radius: 3px;
    border: 1px solid #000;
    font-size: 13px;

    width: 185px;
    height: 40px;

    &:hover {
        border: 1px solid #fff;
        background-color: #000;
        color: #fff;
    }

    &:focus {
        border: 1px solid #fff;
        background-color: #000;
        color: #fff;
    }

    margin-left: 20px;

    @media ${constants.breakpoints.STABLET} {
        margin-left: 0;
        margin-top: 20px;
    }
`


export const FooterContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:center;
`

export const FooterWrapper = styled.div`
    padding: 5rem 5rem;
    max-width: 1300px;
    width: 100%;
    @media ${constants.breakpoints.MOBILE} {
        padding: 5rem 2rem;
    }
`

export const FooterLogo = styled.h3`
    margin: 0;
    color: #000;
    font-size: 2rem;
`

export const FooterRow = styled.div`
    display: flex;
    flex-direction: row;
    :first-child {
        margin-bottom: 10rem;
    }
`

export const FooterCopyright = styled.p`
// font-weight: 600;
`

export const FooterOtherLinks = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: auto;
    font-weight: 600;
`

export const OtherLink = styled.a`
    color: #000;
    margin-left: 20px;
    font-weight: 600;
`

export const FooterMainLink = styled.a`
    color: #000;
    text-decoration: underline;
    font-weight: 600;
`

export const Video = styled.video`


`

export const SuperFooterContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:center;
    background-color: #000;
`

export const SuperFooterWrapper = styled.div`
    padding: 5rem 2rem;
    max-width: 1300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
` 

export const SuperFooterText = styled.h3`
    font-style: normal;
    font-weight: bold;
    font-size: 4rem;
    text-align: center;
    line-height: 4.3rem;

    color: #FFFFFF;

    max-width: 700px;
    width: 100%;

    @media ${constants.breakpoints.MOBILE} {
        font-size: 3rem;
        line-height: 3.3rem;
    }
    
    transition: 500ms;
`

export const PuzzleOriginText = styled.p`
// margin-top: 30%;
    font-size: 1rem;
    // padding: 20px 30px;
    text-align: left;
    width: 100%;

`

export const PuzzleText = styled.h2`
    // margin-top: 2rem;
    // min-width: 200px;
    // width: 100%;
    font-size: 1.3em;
    // padding: 20px;
    // text-align: center; 
    text-align: left;
    font-weight: 400;
`

export const PuzzleIconRow = styled.div`
    display: flex;
    flex-direction: row;

    margin: 10px 0;

    @media ${constants.breakpoints.TABLET} {
        justify-content: center;
    }

    @media ${constants.breakpoints.SMOBILE} {
        flex-direction: column;
    }
` 

export const PuzzleIconCol = styled.div`
    margin: 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
`

export const PuzzleTitleText = styled.h2`
    font-size: 1.3rem;
    font-weight: 400;
    // text-align: center;
    margin: 0;
    display: flex;
    align-items: center;

    @media ${constants.breakpoints.SMOBILE} {
        font-size: 1.1rem;
    }
`

export const PuzzleTextWrapper = styled.div`
    max-width: 450px;
    width: 100%;
    padding: 2rem;

    border: 1px solid #000000;
    border-top: 3px solid #000000;
    // height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: 2rem;

    @media ${constants.breakpoints.TABLET} {
        max-width: 650px;
        margin: 0;
        margin-bottom: 2rem;
    }

    @media ${constants.breakpoints.MOBILE} {
        padding: 1rem;
    }
`

export const PuzzleOpeningName = styled.div`
    margin-top: 1.6rem;

    font-size: 1rem;

    color: #fff;
    background-color: #000;
    
    margin-left: -2rem;
    margin-right: -2rem;
    margin-bottom: -2rem;

    padding: 5px 10px;

    @media ${constants.breakpoints.MOBILE} {
        margin-top: 1rem;
        margin-left: -1rem;
        margin-right: -1rem;
        margin-bottom: -1rem;
    }
`

export const DescriptionText = styled.h3`
    font-style: normal;
    font-weight: bold;
    font-size: 3rem;
    line-height: 4.3rem;

    color: #FFFFFF;

    max-width: 900px;
    width: 100%;

    @media ${constants.breakpoints.MOBILE} {
        font-size: 3rem;
        line-height: 3.3rem;
    }
    
    transition: 500ms;
`

export const DescriptionDarkText = styled.span`
    color: #999;
`