import React, { useState } from 'react'
import { LogoText, BlackLogoText, OutlineLogoText, LogoWrapper } from '../../elements/Landing'
import { Image } from 'antd'
import LogoLarge from '../../images/Logo.svg'
import LogoSmall from '../../images/Logo.small.png'
import LogoSmallBlurred from '../../images/Logo.smallblurred.png'

export const Logo = () => {
    return (
        <LogoWrapper>        
        <Image
            style={{
                imageRendering: ""
            }}
            width="100%"
            src={LogoLarge}
            preview={false}
            placeholder={
            <Image
                preview={false}
                src={LogoSmallBlurred}
                width="100%"
            />
            }
        />
        
      </LogoWrapper>
    )
}
