import styled from 'styled-components'
import { Input } from 'antd'
import constants from '../config/constants'

const { TextArea } = Input

export const ModalWrapper = styled.div`
    padding: 0 1rem;

    width: calc(100% - 2rem);
    height: 50vh;

    display: flex;
    align-items: center;
    justify-content: center; 

    @media ${constants.breakpoints.TABLET} {
        width: 100%;
        margin-top: 3rem;
    }
`

export const FormattedTextBox = styled(TextArea)`
`

export const Modal = styled.div`
    max-width: 600px;
    width: 100%;

    border: 1px solid #000000;
    border-top: 3px solid #000000;

    padding: 30px 30px;
` 

export const ModalTitle = styled.h2`
    margin-top: 0;

` 

export const ModalText = styled.p`
    margin: 10px 0;

    color: #666;
`

export const ModalButtonContainer = styled.div`
    height: 40px;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // justify-content: space-between;
`

export const NakedLink = styled.a`
    text-decoration:none;
    // color: inherit; 
` 