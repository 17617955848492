import React from 'react'
import { LightButton, SuperFooterContainer, SuperFooterText, SuperFooterWrapper } from '../../elements/Landing'
import { HashLink as Link } from 'react-router-hash-link'

export const SuperFooter = () => {
    return (
        <SuperFooterContainer>
            <SuperFooterWrapper>
                <SuperFooterText>
                    Build a stronger repertoire
                </SuperFooterText>
                <Link to='/study'><LightButton style={{margin:"0"}}>
                    Get Started
                </LightButton></Link>
            </SuperFooterWrapper>
        </SuperFooterContainer>
    )
}
