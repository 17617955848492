import React from 'react'
import Chessground from 'react-chessground'
import 'react-chessground/dist/styles/chessground.css'
import constants from '../../config/constants'


export const Board = (props) => { 
    return (
        <Chessground
            movable={props.movable()}
            onMove={props.onMoveTrainer}
            turnColor={props.getTurnColor()}
            lastMove={props.lastMove}
            fen={props.fen}
            orientation={props.userColor}
            width="100%"
            height="100%"
            coordinates={false}
            resizable={true}
            drawable={ { 
                autoShapes: props.shapes // [{ orig: 'a2', brush: 'green' }]
            } }
        />
    )
}
