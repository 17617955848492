import logo from './logo.svg';
import './App.css';
import { AppController } from './views/AppController';
import { Landing } from './views/Landing';
import { DescriptionDarkText } from './elements/Landing';
import HttpsRedirect from 'react-https-redirect'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function App() {
  return (
    <HttpsRedirect>
    <Router>
      <Switch>
          <Route path="/study">
            <AppController/>
          </Route>
          <Route path="/">
            <Landing/>
          </Route>
        </Switch>
    </Router>
    </HttpsRedirect>
  );
}

export default App;
