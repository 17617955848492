import React from 'react'
import { FooterContainer, FooterCopyright, FooterLogo, FooterRow, FooterOtherLinks, FooterWrapper, OtherLink, FooterMainLink } from '../../elements/Landing'

export const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrapper>
                <FooterRow>
                    <FooterLogo>
                        FourKnights
                    </FooterLogo>
                </FooterRow>
                {/* <FooterRow>
                    <FooterMainLink>
                        inquiries@fourknights.com
                    </FooterMainLink>
                </FooterRow> */}
                <FooterRow>
                    <FooterCopyright>
                        © FourKnights 2021
                    </FooterCopyright>
                    <FooterOtherLinks>
                        <OtherLink href="https://www.termsfeed.com/live/dddf594c-4bdc-43c7-9990-27d01dcfe2eb">
                            Privacy
                        </OtherLink>
                        <OtherLink href="https://www.termsfeed.com/live/8fd0ec12-2a8c-4b40-a346-2e7634f61d73">
                            Terms
                        </OtherLink>
                    </FooterOtherLinks>
                </FooterRow>
            </FooterWrapper>
        </FooterContainer>
    )
}
