import React, { useState } from 'react'
import { Board } from '../components/Board/Board'
import { TopControlBox } from '../components/RightControlBox/TopControlBox'
import { RightControlBox } from '../components/RightControlBox/RightControlBox'
import { BoardGrid, BoardGridWrapper, GridWrapper, Grid, Header } from '../elements/Grid'
import { BottomControlBox } from '../components/RightControlBox/BottomControlBox'
import { TrainerStatusBox } from '../components/RightControlBox/TrainerStatusBox'
import { PageHeader, Button, Modal, Tooltip } from 'antd'
import { InfoCircleOutlined, SettingOutlined } from '@ant-design/icons'
import constants from '../config/constants'

export const AppView = (props) => {
    const [settingsModalVisible, setSettingsModalVisible] = useState(false)
    const [infoModalVisible, setInfoModalVisible] = useState(true)

    // const showSettings = () => {
    //     confirm({
    //         title: 'Do you Want to delete these items?',
    //         content: 'Some descriptions',
    //         icon: '',
    //         footer={[
    //             <Button></Button>
    //         ]}

    //         // onOk: () => {
    //         //   console.log('OK');
    //         // },
    //         // onCancel: () => {
    //         //   console.log('Cancel');
    //         // },
    //     })
    // }

    return (
        <>
        <GridWrapper>
            {/* <TopControlBox>
                
            </TopControlBox> */}
            <Header>
                <PageHeader
                    ghost={false}
                    onBack={() => props.setCurrentView(constants.VIEWS.CREATE)}
                    title="New Study"
                    // subTitle="This is a subtitle"
                    extra={[
                        // <Button key="3">Operation</Button>,
                        // <Button key="2">Operation</Button>,
                        // <Button key="1" type="link">
                        // Primary
                        // </Button>,
                        // <Tooltip title="View settings" position="bottom"><Button key="4" type="text" shape="circle" icon={<SettingOutlined />} onClick={() => setSettingsModalVisible(true)}/></Tooltip>,
                        <Tooltip title="More information" position="bottom"><Button key="4" type="text" shape="circle" icon={<InfoCircleOutlined />} onClick={() => setInfoModalVisible(true)} /></Tooltip>,
                    ]}
                />
            </Header>            
            <Grid>
                <BoardGridWrapper>
                    <BoardGrid>
                        <Board
                            fen={props.fen}
                            lastMove={props.lastMove}
                            movable={props.movable}
                            getTurnColor={props.getTurnColor}
                            onMoveTrainer={props.onMoveTrainer}
                            currentView={props.currentView}
                            userColor={props.userColor}
                            shapes={props.drawable}
                        />
                    </BoardGrid>
                </BoardGridWrapper>
            </Grid>
            {/* <RightControlBox>

            </RightControlBox>
            <BottomControlBox>

            </BottomControlBox> */}
            {/* <TrainerStatusBox incorrectMove={props.incorrectMove}/> */}
        </GridWrapper>
        <Modal
          visible={settingsModalVisible}
          footer={[
            <Button key="back" type='link' onClick={() => setSettingsModalVisible(false)}>
              Save Settings
            </Button>,
          ]}
          closable={false}
        >
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </Modal>
        <Modal
          visible={infoModalVisible}
          footer={[
            <Button key="back" type='link' onClick={() => setInfoModalVisible(false)}>
              Start Studying
            </Button>,
          ]}
          closable={false}
        >
            <h1>Studying with FourKnights</h1>
            <p>FourKnights is a spaced repetition chess opening trainer. To get started make a move, and the computer will respond with moves that you gave in your PGN file.</p>
            <p>As you get better at certain lines, the computer will show them less and less. If you make a mistake, the computer will repeat the line.</p>
        
            <p></p>
            <p>Please note that we currently don't support saving progress, so we recommend using smaller PGN files.</p>
        </Modal>
        </>
    )
}
