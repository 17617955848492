import styled from 'styled-components'
import constants from '../config/constants'


export const GridWrapper = styled.div`
    // height: 100%;
    // display: grid;
    // grid-template-columns: 1fr repeat(16, 60px) 1fr;
    // grid-template-rows: 1fr 590px 1fr;
    // grid-gap: 0 1rem;

    // @media ${constants.breakpoints.TABLET} {
    //     grid-template-columns: 1fr repeat(16, 55px) 1fr;
    //     grid-template-rows: 1fr 550px 1fr;
    // }
    // @media ${constants.breakpoints.STABLET} {
    //     grid-template-columns: 1fr repeat(16, 40px) 1fr;
    //     grid-template-rows: 1fr 400px 1fr;
    // }


    // @media ${constants.breakpoints.MOBILE} {
    //     grid-template-rows: 400px 200px 200px;
    //     grid-template-columns: repeat(8, 30px);
    //     grid-gap: 1rem 1rem;
    // }

    padding: 0 1rem;

    width: 100%;
    

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;

    @media ${constants.breakpoints.MOBILE} {
        flex-direction: column;
        padding: 0rem;
        height: 100%;
        // padding-top: 1rem

        position: absolute;
        bottom: 30px;
    }
`

export const Header = styled.div`
    max-width: 650px;
    width: 100%;

    @media ${constants.breakpoints.MOBILE} {
        margin-top: 30px;
    }
    
`

export const Grid = styled.div`
    margin-top: auto;
    max-width: 650px;
    width: 100%;   
    @media ${constants.breakpoints.MOBILE} {
        // margin-top: %;
    } 
`

export const LeftControlBoxGridWrapper = styled.div`
    grid-row: 2 / 2;
    grid-column: 2 / 6;
    height: 100%;

    @media ${constants.breakpoints.MOBILE} {
        grid-row: 2 / 2;
        grid-column: 1 / 10;
    }
`

export const BoardGridWrapper = styled.div`
    // grid-row: 2 / 2;
    // grid-column: 6 / 14 ;
    height: 100px;
    // width: 590px;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative;
    // background-color: red;

    // @media ${constants.breakpoints.MOBILE} {
    //     grid-row: 1 / 1;
    //     grid-column: 1 / 10;
    // }
`

export const BoardGrid = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`

export const RightControlBoxGridWrapper = styled.div`
    grid-row: 2 / 2;
    grid-column: 14 / 18 ;
    height: 100%;

    @media ${constants.breakpoints.MOBILE} {
        grid-row: 3 / 3;
        grid-column: 1 / 10;
    }
`