import React, { useState, useEffect } from 'react'
import { Modal, ModalButtonContainer, ModalText, ModalTitle, ModalWrapper, NakedLink } from '../../elements/PgnParser'
import { PgnTextBox } from './PgnTextBox'
import { Button } from 'antd'
import { getMoveTreeMulti } from '../../app/pgnImporter'
import { Tooltip } from 'antd'
import constants from '../../config/constants'
import { Radio } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const example = "1. e4 c5 2. Nf3 d6 3. Nc3 Nf6 4. d4 cxd4 5. Nxd4 a6 6. Be3 * \n1. e4 c5 2. Nf3 d6 3. Nc3 Nc6 4. d4 cxd4 5. Nxd4 Nf6 6. Bg5 *"

const options = ['White', 'Black']

export const PgnParserModal = ({ setMoveTree, setCurrentView }) => {
    const [loading, setLoading] = useState(false)
    const [buttonLabel, setButtonLabel] = useState('Start')
    const [color, setColor] = useState('White')
    const [pgn, setPgn] = useState('')
    const [error, setError] = useState(false)

    const onSubmit = (e) => {
        setLoading(true)
        setButtonLabel('Validating')

        setTimeout(() => {
            const moveTree = getMoveTreeMulti(pgn, color.toLowerCase())
            if(moveTree) {
                setMoveTree(moveTree)
                setCurrentView(constants.VIEWS.STUDY)
            } else {
                setError(true)
                setLoading(false)
                setButtonLabel('Start')
            }
        }, 1000)
    }

    const setExamplePgn = () => {
        setPgn(example)
    }

    return (
        <ModalWrapper>
        <Modal>
            <ModalTitle>
                Let's get started
            </ModalTitle>
            <ModalText>
                Paste a PGN below that you would like to practice. It can be many different variations of one line, or it can be a whole repertoire.
            </ModalText>
            <PgnTextBox pgn={pgn} setPgn={setPgn} />
            <Radio.Group
                options={options}
                onChange={(e) => setColor(e.target.value)}
                value={color}
                optionType="button"
                buttonStyle="solid"
                style={{
                    marginTop: '15px'
                }}
            />
            <ModalText>
                Don't know where to start? Try an <Tooltip placement="bottom"><NakedLink onClick={() => setExamplePgn()}>example repertoire</NakedLink></Tooltip>. 
            </ModalText>
            <ModalButtonContainer>
                {/* <Button danger type="link">Back</Button> */}
                {error && 
                    <p style={{ marginRight: 'auto', color: '#ff7875'}}><ExclamationCircleOutlined /> That pgn doesn't look right</p>
                }
                <Button loading={loading} onClick={() => onSubmit()} danger={error}>{buttonLabel}</Button>
            </ModalButtonContainer>
        </Modal>
        </ModalWrapper>
    )
}
