import React, { useEffect, useState } from 'react'
import constants from '../config/constants'
import { AppView } from './AppView'
import { useChessBoard } from '../hooks/useChessBoard'
import { useTrainer } from '../hooks/useTrainer'
import { getMoveTreeMulti } from '../app/pgnImporter'
import { PgnParserModal } from '../components/PgnParser/PgnParserModal'


const data = [
    {
      key: '1',
      repertoire: 'Repertoire 1',
      color: 'white',
      move_tree: getMoveTreeMulti('1. e4 c5 2. Nf3 d6 3. Nc3 a6 4. d4 cxd4 5. Nxd4 *')
    },
    {
      key: '2',
      repertoire: 'Repertoire 2',
      color: 'black',
      move_tree: getMoveTreeMulti('1. e4 c5 2. Nf3 d6 3. Nc3 Nf6 4. d4 cxd4 5. Nxd4 a6 6. Be3 * \n 1. e4 c5 2. Nf3 d6 3. Nc3 Nc6 4. d4 cxd4 5. Nxd4 Nf6 6. Bg5 *', 'black')
    },
    {
      key: '3',
      repertoire: 'Repertoire 3',
      color: 'white',
      move_tree: getMoveTreeMulti('1. e4 c5 2. Nf3 d6 3. Nc3 Nf6 4. d4 Qa5 5. Bd2 *')
    }, 
];

export const AppController = () => {
    const [moveTree, setMoveTree] = useState(constants.NULL)

    const [currentView, setCurrentView] = useState(constants.VIEWS.CREATE) 
    const [selectedRepertoire, setSelectedRepertoire] = useState(constants.NULL)    
    const [repertoireList, setRepertoireList] = useState([])
    const [currentRepertoireData, setCurrentRepertoireData] = useState({})
    const [refresh, setRefresh] = useState(0)


    const [lastMove, fen, updateFen, movable, getTurnColor, makeMove, userColor, setUserColor, reset, undo, setUndo] = useChessBoard(moveTree)
    const [onMoveTrainer, setTrainerMoveTree, incorrectMove, setIncorrectMove, start, drawable] = useTrainer(moveTree, makeMove, getTurnColor, userColor, setUserColor, updateFen, reset, undo, setUndo, currentView, currentRepertoireData, refresh)

    useEffect(() => {
        setRepertoireList(data)
    }, [])

    useEffect(() => {
        if(currentRepertoireData) {
            setMoveTree(currentRepertoireData.move_tree)
        }
    }, [currentRepertoireData])

    useEffect(() => {
        setTrainerMoveTree(moveTree)
    }, [moveTree])

    useEffect(() => {
        setCurrentRepertoireData(repertoireList[0])
    }, [repertoireList])

    return (
        <>
            { currentView == constants.VIEWS.STUDY && 
                <AppView
                    setCurrentView={setCurrentView}
                    repertoireList={repertoireList}
                    selectedRepertoire={selectedRepertoire}
                    setSelectedRepertoire={setSelectedRepertoire}
                    currentView={currentView}
                    setCurrentView={setCurrentView}
                    currentRepertoireData={currentRepertoireData}
                    moveTree={moveTree}
                    fen={fen}
                    lastMove={lastMove}
                    movable={movable}
                    getTurnColor={getTurnColor}
                    onMoveTrainer={onMoveTrainer}
                    userColor={userColor}
                    incorrectMove={incorrectMove}
                    drawable={drawable}
                />
            }
            { currentView == constants.VIEWS.CREATE && 
                <PgnParserModal setMoveTree={setMoveTree} setCurrentView={setCurrentView}/>
            }
        </>
    )
}
