import Chess from 'chess.js'
import MoveTree from './moveTree'
import RepetitionMoveTree from './repetitionMoveTree'
const pgnParser = require('pgn-parser')

/**
 * Load pgns into the chess object, 
 * insert verbose moves depth first into a move tree
 * 
 * 
 */

export function getMoveTree(pgn) {
    const chess = new Chess()
    const tree = new RepetitionMoveTree()

    chess.load_pgn(pgn, {sloppy: true})
    const moves = chess.history({ verbose: true })

    moves.forEach(move => {
        if(!tree.advanceCursor(move)) {
            tree.insertMove(move)
            tree.advanceCursor(move)
        }
    });
    tree.resetCursor()

    return tree
}

export function getMoveTreeMulti(pgn, color='white') {
    const tree = new MoveTree(color)
    const chess = new Chess()
    try {
        const result = pgnParser.parse(pgn)

        if (result.length == 0) {
            return null
        } 
        result.forEach(game => {
            // For each individual game, we turn them into a chess game
            const moves = getMoves(chess, game)
            MoveTree.insertGame(tree, moves)
        })
        tree.resetCursor()
    
        return tree
    } catch {
        return null
    }
}

function getMoves(chess, game) {
    chess.reset()
    game.moves.forEach(move => {
        chess.move(move['move'])
    })
    return chess.history({ verbose: true })
}