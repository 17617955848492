import Chess from 'chess.js'
import constants from '../config/constants'
var ebisu = require('ebisu-js')

class MoveTree {
    constructor(color='white') {
        this.tree = {
            subsequent: {},
            fen: constants.FEN.START
        }
        this.color = color
        this.cursor = this.tree
        this.path_slug = ""
        this.path = []
        this.move_number = 1
        this.ply_number = 0
    }

    printTree(current, indent, last, current_time) {
        if (!current.color && current.subsequent) {
            console.log(`${indent}+- root`)
            indent += "  " 
        } else if (!current.color) {
            return
        } else {
            if(current.color == 'w' && this.color === 'white' || current.color == 'b' && this.color === 'black') {
                if(!current.scheduling.last_studied) {
                    var prob = -Infinity
                } else {
                    var prob  = ebisu.predictRecall(current.scheduling.model, Math.abs((Date.parse(current.scheduling.last_studied) - current_time)  / 36e2))
                } 
                console.log(`${indent}+- ${current.move_number}: ${current.from}, ${current.to} (${prob})`)
            } else {
                console.log(`${indent}+- ${current.move_number}: ${current.from}, ${current.to} (${current.worst.move.move_number}: ${current.worst.move.from}, ${current.worst.move.to})`)
            }
            indent += last ? "  " : "|  "
        }
        
        var children = Object.keys(current.subsequent)
        var i = 0
        children.forEach(slug => {
            this.printTree(current.subsequent[slug], indent, i === children.length - 1, current_time)
            i += 1
        })
    }

    isValidMove(move) {
        const move_slug = MoveTree.getSlug(move)
        return move_slug in this.cursor.subsequent
    }

    getValidMoves() {
        var valid_moves = []
        for (const move in this.getCursor().subsequent) {
            valid_moves.push(this.getCursor().subsequent[move])
        }
        return valid_moves
    }

    getNextMove(current_time) {
        const moves = Object.keys(this.getCursor().subsequent)
        const logProbabilities = []
        moves.forEach(move => {
            var move_object = this.getCursor().subsequent[move]
            if(!move_object.worst.last_studied) {
                logProbabilities.push(-Infinity)
            } else {
                logProbabilities.push(ebisu.predictRecall(move_object.worst.model, Math.abs(Date.parse(move_object.worst.last_studied) - current_time) / 36e2))
            }            
        })
        const minIndex = logProbabilities.reduce((iMin, x, i, arr) => x < arr[iMin] ? i : iMin, 0);
        return this.getCursor().subsequent[moves[minIndex]]
    }

    setCursor(path) {
        const path_array = path.split('/')
        this.resetCursor()

        for (var i = 0; i < path_array.length; i++) {
            this.advanceCursorSlug(path_array[i])
        }
        return this.cursor
    }

    advanceCursorSlug(move_slug) {
        if(move_slug in this.cursor.subsequent) {
            this.path.push(move_slug)
            this.path_slug = MoveTree.getPathSlug(this.path)
            this.cursor = this.cursor.subsequent[move_slug]
            this.setPlyNumber(this.getPlyNumber() + 1)
            this.setMoveNumber(this.getMoveNumber() + (this.getPlyNumber() % 2 === 0 ? 1 : 0))
            return this.cursor
        }
        return null
    }

    advanceCursor(move) {
        const move_slug = MoveTree.getSlug(move)
        if(move_slug in this.cursor.subsequent) {
            this.path.push(move_slug)
            this.path_slug = MoveTree.getPathSlug(this.path)
            this.cursor = this.cursor.subsequent[move_slug]
            this.setPlyNumber(this.getPlyNumber() + 1)
            this.setMoveNumber(this.getMoveNumber() + (this.getPlyNumber() % 2 === 0 ? 1 : 0))
            return this.cursor
        }
        return null
    }

    reverseCursor() {
        if (this.tree === this.cursor) return this.tree

        var stack = []
        var curr = this.tree
        var i = 0

        while (curr != this.cursor) {
            curr = curr.subsequent[this.path[i]]
            stack.push(curr)
            i += 1
        }
        
        curr = stack.pop()
        this.path.pop()
        
        return curr
    }

    recordMove(move, current_time, correct=false) {
        if (correct) {
            const move_slug = MoveTree.getSlug(move)
            const move_object = this.cursor.subsequent[move_slug]
            const current_date = current_time
            const time_elapsed = Date.parse(move_object.scheduling.last_studied) ? Math.abs((Date.parse(move_object.scheduling.last_studied) - current_date) / 36e2) : 0.000001
            const updated_model = ebisu.updateRecall(move_object.scheduling.model, 1, 1, time_elapsed)

            move_object.scheduling.model = updated_model
            move_object.scheduling.last_studied = current_date.toISOString()
        } else {
            const moves = Object.keys(this.getCursor().subsequent)
            const current_date = current_time

            moves.forEach(move => {
                var move_object = this.getCursor().subsequent[move]
                var time_elapsed = Date.parse(move_object.scheduling.last_studied) ? Math.abs((Date.parse(move_object.scheduling.last_studied) - current_date) / 36e2) : 0.000001
                var updated_model = ebisu.updateRecall(move_object.scheduling.model, 0, 1, time_elapsed)

                move_object.scheduling.model = updated_model
                move_object.scheduling.last_studied = current_date.toISOString()
            })
        }
        const branch_min_object = this.getBranchMin()
        this.propagate(branch_min_object)
    }

    getBranchMin() {
        // calculate the worst probability in this branch of subsequents.
        const current_branch = Object.keys(this.cursor.subsequent)
        var worst_probability = Infinity
        var worst_object = {}
        var curr_probability = Infinity

        current_branch.forEach(move => {
            var move_object = this.cursor.subsequent[move]
            if(!move_object.scheduling.last_studied) {
                curr_probability = -Infinity
            } else {
                curr_probability = ebisu.predictRecall(move_object.scheduling.model, Math.abs((Date.parse(move_object.scheduling.last_studied) - new Date())  / 36e2))
            } 
            
            if(curr_probability < worst_probability) {
                worst_probability = curr_probability
                worst_object = move_object
            }
        })

        return {
            ...worst_object,
            worst_probability: worst_probability
        }
    }

    propagate(branch_min_object) {
        // We traverse down the tree to just before the cursor

        var stack = []
        var curr = this.tree
        var i = 0

        while (curr != this.cursor) {
            curr = curr.subsequent[this.path[i]]
            stack.push(curr)
            i += 1
        }
        while (stack.length > 0) {
            curr = stack.pop()
            
            if (curr.worst) {
                var curr_probability = Date.parse(curr.worst.last_studied) ? ebisu.predictRecall(curr.worst.model, (Math.abs(Date.parse(curr.worst.last_studied) - new Date())  / 36e2)) : 0
                console.log(curr_probability)
                console.log(branch_min_object.worst_probability)
                console.log(curr_probability > branch_min_object.worst_probability)
                if (curr.worst.path === branch_min_object.path || curr_probability === 0 || curr_probability > branch_min_object.worst_probability) {
                    
                    curr.worst = {
                        move: branch_min_object,
                        path: branch_min_object.path,
                        model:  branch_min_object.scheduling.model,
                        last_studied:  branch_min_object.scheduling.last_studied
                    }
                }
            }
        }
    }

    getFen() {
        if(this.cursor.fen) {
            return this.cursor.fen
        }
        return null
    }

    getCursor() {
        return this.cursor
    }

    resetCursor() {
        this.cursor = this.tree
        this.path = []
        this.path_slug = ""
        this.move_number = 1
        this.ply_number = 0

        // this.printTree(this.tree, "", false, new Date())
        return this.cursor
    }

    isStart() {
        return !this.cursor.color && this.cursor.subsequent
    }

    isEnd() {
        return Object.keys(this.cursor.subsequent) == 0
    }

    getMoveNumber() {
        return this.move_number
    }

    setMoveNumber(number) {
        this.move_number = number
        return this.move_number
    }

    getPlyNumber() {
        return this.ply_number
    }

    setPlyNumber(number) {
        this.ply_number = number
        return this.ply_number
    }

    getPath() {
        return this.path
    }

    static getSlug(move) {
        return move.flags + move.from + move.piece + move.san + move.to
    }

    static getPathSlug(path) {
        return path.join("/")
    }

    static insertGame(move_tree, moves) {
        const chess = new Chess()
        var path = []
        move_tree.resetCursor()
        moves.forEach(move => {
            chess.move(move)
            var move_slug = MoveTree.getSlug(move)
            path.push(move_slug)
            if(!move_tree.advanceCursor(move)) {
                MoveTree.insertMove(move_tree, move, move.color === 'b' ? 'black' : 'white', path, chess.fen())
                move_tree.advanceCursor(move)
            }
        });
    }

    static insertMove(move_tree, move, color, path, fen) {
        const move_slug = MoveTree.getSlug(move)
        if (color === move_tree.color) {
            move_tree.cursor.subsequent[move_slug] = {
                ...move,
                ply_number: move_tree.getPlyNumber(),
                move_number: move_tree.getMoveNumber(),
                fen: fen,
                statistics: {
                    attempts: 0,
                    correct: 0
                },
                scheduling: {
                    last_studied: null,
                    model: [4, 4, 24]
                },
                subsequent: {},
                path: MoveTree.getPathSlug(path)
            }
        } else {
            move_tree.cursor.subsequent[move_slug] = {
                ...move,
                ply_number: move_tree.getPlyNumber(),
                move_number: move_tree.getMoveNumber(),
                fen: fen,
                worst: {
                    move: {},
                    last_studied: null,
                    model: [4, 4, 24],
                    path: ""
                },
                subsequent: {},
                path: MoveTree.getPathSlug(path)
            }
        }
    }

    insertBuildMove(move, fen) {
        const move_slug = MoveTree.getSlug(move)
        console.log([...this.path, move_slug])

        if (move.color === this.color) {
            this.cursor.subsequent[move_slug] = {
                ...move,
                ply_number: this.getPlyNumber(),
                move_number: this.getMoveNumber(),
                fen: fen,
                statistics: {
                    attempts: 0,
                    correct: 0
                },
                scheduling: {
                    last_studied: null,
                    model: [4, 4, 24]
                },
                subsequent: {},
                path: MoveTree.getPathSlug([...this.path, move_slug])
            }
        } else {
            this.cursor.subsequent[move_slug] = {
                ...move,
                ply_number: this.getPlyNumber(),
                move_number: this.getMoveNumber(),
                fen: fen,
                worst: {
                    move: {},
                    last_studied: null,
                    model: [4, 4, 24],
                    path: "" 
                },
                subsequent: {},
                path: MoveTree.getPathSlug([...this.path, move_slug])
            }
        }

        return this.advanceCursor(move)
    }
}


export default MoveTree