import { useEffect, useState } from "react"
import Chess from "chess.js"

export const useChessBoard = (moveTree) => {
    const [chess, setChess] = useState(new Chess())
    const [lastMove, setLastMove] = useState(null)
    const [fen, setFen] = useState(chess.fen())
    const [userColor, setUserColor] = useState()
    const [undo, setUndo] = useState(false)

    useEffect(() => {
        setFen(chess.fen())
        setLastMove(lastMove)
    }, [undo])

    const reset = () => {
        if(moveTree) {
            moveTree.resetCursor()
        }
        chess.reset()
        setFen(chess.fen())
        setLastMove(null)
    }

    const calcMovable = () => {
        const dests = new Map()
        chess.SQUARES.forEach(s => {
          const ms = chess.moves({ square: s, verbose: true })
          if (ms.length) dests.set(s, ms.map(m => m.to))
        })
        return {
          free: false,
          dests,
          color: getTurnColor()
        }
    }

    const makeMove = (from, to) => {
        let moveObj = null
        if(to) {
            moveObj = { from: from, to: to, promotion: 'q'}
        } else {
            moveObj = from
        } 
        let move = chess.move(moveObj, {verbose: false})
        setFen(chess.fen())
        setLastMove([from, to])        
    }

    const getTurnColor = () => {
        return chess.turn() === "w" ? "white" : "black"
    }

    const updateFen = (fen) => {
        reset()
        chess.load(fen)
        setFen(fen)
    }

    return [lastMove, fen, updateFen, calcMovable, getTurnColor, makeMove, userColor, setUserColor, reset, undo, setUndo]
}