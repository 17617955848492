/**
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 */







 var ebisu = require('ebisu-js')

 /**
  * We store ebisu stats for each ply, but we do this only for convenience. The only
  * stats that we look at are the ones stored in the userColor's plies. 
  * 
  * i.e. If we are playing as white, we look 
  * 
  * 
  */
 class RepetitionMoveTree {
     constructor(color='black') {
         this.tree = {
             subsequent: {}
         }
         this.color = color
         this.cursor = this.tree
         this.move_number = 1
         this.ply_number = 1
         this.previous_move = null
     }
 
     isValidMove(move) {
         const move_slug = RepetitionMoveTree.getSlug(move)
         return move_slug in this.cursor.subsequent
     }

     getValidMoves() {
        var valid_moves = []
        console.log(this.getCursor())
        for (const move in this.getCursor().subsequent) {
            console.log(this.getCursor())
            valid_moves.push(this.getCursor().subsequent[move])
        }
        return valid_moves
     }
 
     /**
      * We are interested in calculating the move the user is most likely to be unfamiliar
      * with or which variation has most likely been forgotten. 
      */
     getNextMove() {
         const moves = Object.keys(this.getCursor().subsequent)
         const logProbabilities = []
         moves.forEach(move => {
             var move_object = this.getCursor().subsequent[move]
             if(!move_object.scheduling.last_studied) {
                 logProbabilities.push(0)
             } else {
                 logProbabilities.push(ebisu.predictRecall(move_object.scheduling.model, Math.abs(Date.parse(move_object.scheduling.last_studied) - new Date()  / 36e5)))
             }            
         })
         const minIndex = logProbabilities.reduce((iMin, x, i, arr) => x < arr[iMin] ? i : iMin, 0);
         return this.getCursor().subsequent[moves[minIndex]]
     }
 
     advanceCursor(move) {
         const move_slug = RepetitionMoveTree.getSlug(move)
         if(move_slug in this.cursor.subsequent) {
             this.cursor = this.cursor.subsequent[move_slug]
             return this.cursor
         }
         return null
     }
 
     /**
      * Correct answer is applied to only the move that was made, not any other moves
      * in the branch.
      */
     recordCorrectMove(move) {
         const move_slug = RepetitionMoveTree.getSlug(move)
         const move_object = this.cursor.subsequent[move_slug]
         const current_date = new Date()
         const time_elapsed = Date.parse(move_object.scheduling.last_studied) ? Math.abs((Date.parse(move_object.scheduling.last_studied) - current_date) / 36e5) : 0.0001
         const updated_model = ebisu.updateRecall(move_object.scheduling.model, 1, 1, time_elapsed)
 
         move_object.scheduling.model = updated_model
         move_object.scheduling.last_studied = current_date.toISOString()
     }
 
     /**
      * Incorrect answer is applied to every move in the branch, because user was unable
      * to recognize any of those moves as correct. 
      * 
      * i.e. if two branches exist where e4 and Nf6 are correct, but the user moves h3. 
      * Both the e4 and the Nf6 branch will be punished. 
      * 
      * No parameter required because all that matters is that there is no valid
      * branch.
      */
     recordIncorrectMove() {
         const moves = Object.keys(this.getCursor().subsequent)
         const current_date = new Date()
 
         moves.forEach(move => {
             var move_object = this.getCursor().subsequent[move]
             var time_elapsed = Date.parse(move_object.scheduling.last_studied) ? Math.abs((Date.parse(move_object.scheduling.last_studied) - current_date) / 36e5) : 0.0001
             var updated_model = ebisu.updateRecall(move_object.scheduling.model, 0, 1, time_elapsed)
 
             move_object.scheduling.model = updated_model
             move_object.scheduling.last_studied = current_date.toISOString()
         })
     }
 
     getCursor() {
         return this.cursor
     }
 
     resetCursor() {
         this.cursor = this.tree
         return this.cursor
     }
 
     isEnd() {
         return Object.keys(this.cursor.subsequent) == 0
     }
 
     getMoveNumber() {
         return this.move_number
     }
 
     setMoveNumber(number) {
         this.move_number = number
         return this.move_number
     }
 
     getPlyNumber() {
         return this.ply_number
     }
 
     setPlyNumber(number) {
         this.ply_number = number
         return this.ply_number
     }
 
     resetStats() {}
 
     static getSlug(move) {
         return move.flags + move.from + move.piece + move.san + move.to
     }
 
     static insertGame(move_tree, moves) {
         move_tree.resetCursor()
         moves.forEach(move => {
             if(!move_tree.advanceCursor(move)) {
                 RepetitionMoveTree.insertMove(move_tree, move)
                 move_tree.advanceCursor(move)
             }
         });
     }
 
     static insertMove(move_tree, move) {
         const move_slug = RepetitionMoveTree.getSlug(move)
         move_tree.cursor.subsequent[move_slug] = {
             ...move,
             ply_number: move_tree.getPlyNumber(),
             move_number: move_tree.getMoveNumber(),
             statistics: {
                 attempts: 0,
                 correct: 0
             },
             scheduling: {
                 last_studied: null,
                 model: [4, 4, 24]
             },
             subsequent: {}
         }
         move_tree.setPlyNumber(move_tree.getPlyNumber() + 1)
         move_tree.setMoveNumber(move_tree.getMoveNumber() + move_tree.getPlyNumber() % 2 === 0 ? 1 : 0)
     }
 }
 
 export default RepetitionMoveTree