import React, { useEffect, useState } from 'react'
import Chessground from 'react-chessground'
import 'react-chessground/dist/styles/chessground.css'
import constants from '../../config/constants'
import { useChessBoard } from '../../hooks/useChessBoard'
import { useTrainer } from '../../hooks/useTrainer'
import { getMoveTreeMulti } from '../../app/pgnImporter'


const pgn = "1.c4 e5 2.Nc3 Nc6 3.g3 g6 4.Bg2 Bg7 5.Rb1 a5 6.a3 Nge7 7.e3 d6 8.b4 axb4 9.axb4 O-O 10.Nge2 Be6 11.b5 Na5 12.d3 c6 13.O-O *"

export const DemoBoard = (props) => { 
    const [moveTree, setMoveTree] = useState(getMoveTreeMulti(pgn, 'white'))

    const [currentView, setCurrentView] = useState(constants.VIEWS.STUDY) 
    const [repertoireList, setRepertoireList] = useState([])
    const [currentRepertoireData, setCurrentRepertoireData] = useState({})
    const [refresh, setRefresh] = useState(0)


    const [lastMove, fen, updateFen, movable, getTurnColor, makeMove, userColor, setUserColor, reset, undo, setUndo] = useChessBoard(moveTree)
    const [onMoveTrainer, setTrainerMoveTree, incorrectMove, setIncorrectMove, start, drawable, skip] = useTrainer(moveTree, makeMove, getTurnColor, userColor, setUserColor, updateFen, reset, undo, setUndo, currentView, currentRepertoireData, refresh)

    useEffect(() => {
        setTrainerMoveTree(moveTree)
        // onMoveTrainer('c2', 'c4')
        // onMoveTrainer('b1', 'c3')
    }, [moveTree])

    useEffect(() => {
        if(fen == constants.FEN.START) {
            skip(5)
        }
    }, [fen])
    
    return (
        <Chessground
            movable={movable()}
            onMove={onMoveTrainer}
            turnColor={getTurnColor()}
            lastMove={lastMove}
            fen={fen}
            orientation={userColor}
            width="100%"
            height="100%"
            coordinates={false}
            resizable={true}
            // viewOnly={true}
            drawable={ { 
                autoShapes: drawable // [{ orig: 'a2', brush: 'green' }]
            } }
        />
    )
}
