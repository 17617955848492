module.exports = {
    API: {
        BASE_URL_DEV: 'http://localhost:5000'
    },
    VIEWS: {
        CREATE: 'create',
        STUDY: 'study'
    },
    FEN: {
        START: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
    },
    NULL: null,
    breakpoints: {
        SMOBILE: "only screen and (max-width: 550px)",
        MOBILE: "only screen and (max-width: 720px)",
        STABLET: "only screen and (max-width: 878px)",
        TABLET: "only screen and (max-width: 1280px)",
        XTABLET: "only screen and (max-width: 1400px)"
    }
}